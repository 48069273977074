import type { Env } from './env.d';

export const env: Env = {
  BRAND: 'teddy',
  STOREFRONT_ACCESS_TOKEN: '029a8a0de307463d89ac895e064a002b',
  STOREFRONT_API_URL:
    'https://teamteddy.myshopify.com/api/2023-04/graphql.json',
  SHOPIFY_SHOP_ID: '64079397119',
  KEYCHAIN_ACCESS_GROUP: 'P72357Q2FS.nz.teddy.shop',
  GOOGLE_PLACES_API_KEY: 'AIzaSyA7Wj5Nyh8xQ83Lztp-X_NsG5KFd6EPMV4',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDwcI9KuRlUpuC4aIQlueuXCv_zI1yZZck',
  STRIPE_PUBLISHABLE_KEY:
    'pk_live_51L8HcuB0hi1h47vIgB8ErUIORWtcKMvAQhNDBQp1F9vIKKrrsEaRvfzCTbi0s5x2K04goirumoYLMRlZNLUbEPmR00e7X88qkE',
  STRIPE_ACCOUNT_ID: 'acct_1L8HcuB0hi1h47vI',
  USE_FIREBASE_EMULATORS: false,
  FIREBASE_EMULATORS_HOST: null,
  APPLE_MERCHANT_ID: 'merchant.nz.teddy.storefront',
  MIXPANEL_TOKEN: '96369fb59a8fe52cdd8709f0b2ab6622',
  TRPC_URL: 'https://teddy-next-v2.vercel.app/api/trpc',
  TALKJS_APP_ID: 'sSrOUBUJ',
  FACEBOOK_APP_ID: '656703759446432',
  FIREBASE_WEB_CONFIG: {
    apiKey: 'AIzaSyCuWI708RIofjb9dq6Ep5ZUWCmuYbkuI6o',
    authDomain: 'teddy-nz.firebaseapp.com',
    projectId: 'teddy-nz',
    storageBucket: 'teddy-nz.appspot.com',
    messagingSenderId: '544345997022',
    appId: '1:544345997022:web:ae4565eb092c22b0464c4f',
    measurementId: 'G-FV7H058HCK',
  },
  GOOGLE_MAP_PROXY_URL: 'https://teddy-next-v2.vercel.app/api/google-map',
};

export const {
  BRAND,
  STOREFRONT_ACCESS_TOKEN,
  STOREFRONT_API_URL,
  SHOPIFY_SHOP_ID,
  KEYCHAIN_ACCESS_GROUP,
  GOOGLE_PLACES_API_KEY,
  GOOGLE_MAPS_API_KEY,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_ACCOUNT_ID,
  USE_FIREBASE_EMULATORS,
  FIREBASE_EMULATORS_HOST,
  APPLE_MERCHANT_ID,
  MIXPANEL_TOKEN,
  TRPC_URL,
  TALKJS_APP_ID,
  FACEBOOK_APP_ID,
  FIREBASE_WEB_CONFIG,
  GOOGLE_MAP_PROXY_URL,
} = env;
